<template>
  <div>
    <DataTable
        class="mt-5"
        :loading="isLoading"
        :value="currentTabState.tableData"
        :rows="currentTabState.rowsPerPage"
        :totalRecords="currentTabState.totalRecords"
        :sortField="currentTabState.sortField"
        :sortOrder="currentTabState.sortOrder"
        :rowGroupMode="rowGroupMode"
        :groupRowsBy="groupRowsBy"
        :rowClass="rowClass"
        selectionMode="single"
        @row-select="onRowSelect"
        @row-click="onRowClick"
        @page="onPageChange"
        @sort="onSort"
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowsPerPageOptions="[1, 2, 5, 10, 20]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        :lazy="true"
        :expandedRows.sync="currentTabState.expandedRows"
    >
<!--      &lt;!&ndash; Conditional Expander Column based on Vuex State &ndash;&gt;-->
<!--      <Column-->
<!--          v-if="enableExpander"-->
<!--          :expander="true"-->
<!--          :headerStyle="{'width': '3rem'}"-->
<!--      />-->

      <!-- Standard columns -->
      <Column
          v-for="col in columns"
          :key="col.field"
          :field="col.field"
          :header="col.header"
          :sortable="col.sortable"
          :headerStyle="{ width: col.width }"
          :expander="col.expander ? true : undefined"
          :styles="{ 'text-align': col.align }">
        <!-- Custom slot content within Column if needed -->
        <template v-if="col.custom" #body="slotProps">
          <slot :name="col.slotName" :rowData="slotProps.data"/>
        </template>
      </Column>
      <template #empty>
        No records found.
      </template>

      <!-- Expansion Template -->
      <template #expansion="{ data }">
        <slot name="expansion" :data="data"></slot> <!-- Scoped slot for expansion -->
      </template>


    </DataTable>
    <Paginator  :first.sync="currentTabState.first"  template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                @page="onPageChange" :rows.sync="currentTabState.rowsPerPage" :totalRecords="currentTabState.totalRecords" :rowsPerPageOptions="[1, 2, 5, 10, 20]">
     </Paginator>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  props: {
    columns: {
      type: Array,
      required: true,
    },
    rowGroupMode: {
      type: String,
      default: null
    },
    groupRowsBy: {
      type: String,
      default: null
    },
    rowClass: {
      type: Function,
      default: null
    },
    enableRowClick: {
      type: Boolean,
      default: true
    },
    enableExpander: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      expandedRows: []
    }
  },
  computed: {
    ...mapState("sales", {
      currentTabState: state => state.tabs[state.currentTab],
      isLoading: state => state.isLoading,
      currentTab: state => state. currentTab,
    }),
  },

  methods: {
    ...mapActions("sales", ["updateTableSettings", "updateSortOptions",]),

    onRowSelect(event) {
      this.$emit("row-select", event);
    },
    onRowClick(event) {
      if (this.enableRowClick) {
        this.$emit("row-click", event);
      }
    },
    onPageChange(event) {
      this.updateTableSettings({
        rows: event.rows,
        page: event.page, // This is 0-based in the event
      });
    },
    onSort(event) {
      this.updateSortOptions({
        field: event.sortField,
        order: event.sortOrder,
      });
    },
  },
};
</script>

