<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Sales Orders & Despatches" backTo="internal">
      <router-link
          :to="{ name: 'sales-create' }"
          class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path d="M12 5v14M5 12h14"/>
        </svg>
        <span>Create Sales</span>
      </router-link>
    </page-header>

    <div class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <!-- Toolbar with Tab Headers and Search Controls -->
        <div class="w-full flex items-center justify-between">
          <div class="flex -mx-0 mr-2">
            <TabHeaders :tabs="tabs" :selectedTab="currentTab" @tab-selected="onTabSelected"/>
          </div>
          <div class="flex flex-col flex-1">
            <div class="flex justify-end">
              <div class="flex items-center mr-4">
                <label class="mr-2">From Date:</label>
                <Calendar
                    class="w-full lg:w-auto"
                    v-model="fromDateInput"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                />
              </div>
              <div class="flex items-center ">
                <label class="mr-2">To Date:</label>
                <Calendar
                    class="w-full lg:w-auto"
                    v-model="toDateInput"
                    dateFormat="dd/mm/yy"
                    :showIcon="true"
                />
              </div>


              <input
                  type="text"
                  class="ml-6 w-full lg:w-auto rounded-l bge-input bge-input-spacing"
                  placeholder="Search"
                  v-model="searchTextInput"
                  @keydown.enter="triggerSearch"
              />
              <button
                  @click="triggerSearch"
                  class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
              >
                <Icon iconType="search"/>
              </button>
            </div>
          </div>
        </div>

        <!-- Tab Content -->
        <TabContent :currentTab="currentTab">
          <template v-slot:[currentTab]>
            <DataTableComponent :columns="purchase_columns" @row-click="onRowClick" rowGroupMode="rowspan"
                                :key="currentTab" :rowClass="rowClass" :enableExpander="currentTabState.enableExpander"
            >

              <template v-slot:status-slot="{ rowData }">
                {{ checkStatusAndReturnUppercase(rowData.status) }}
              </template>

              <template v-slot:order-date-slot="{ rowData }">
                {{ formatTimestamp(rowData.order_date) }}
              </template>

              <template v-slot:delivery-date-slot="{ rowData }">
                <!--                  <span :class="getDeliveryDateClass(rowData)">-->
                {{ formatTimestamp(rowData.requested_delivery_date) }}
                <!--                  </span>-->
              </template>


              <template v-slot:completed-date-slot="{ rowData }">
                {{ formatTimestamp(rowData.completed_date) }}
              </template>

              <template v-slot:received-date-slot="{ rowData }">
                {{ formatTimestamp(rowData.received_date) }}
              </template>

              <template v-slot:approved-date-slot="{ rowData }">
                {{ formatTimestamp(rowData.approved_date) }}
              </template>

              <template v-if="!currentTabState.enableExpander" v-slot:action-slot="{ rowData }">
                <div class="flex justify-end space-x-2">

                  <AppButton v-if="showCancelOrderButton(rowData.status)" color="red" label="" :icon="true"
                             @click.native.stop="cancelOrder(rowData.sales_order_id)">
                    <template v-slot:icon>
                      <Icon iconType="circle-minus"/>
                    </template>
                  </AppButton>


                  <Knob :value="getDispatchedCounts(rowData)" :min="0" :max="getTotalItems(rowData)" :size="35"
                        valueColor="#4CAF50" :strokeWidth="10" :title="getDispatchedCounts(rowData)"/>

                  <AppButton v-if="showCopyOrderButton(rowData.status)" color="blue" label="" :icon="true"
                             @click.native.stop="copyOrder(rowData.sales_order_id)">
                    <template v-slot:icon>
                      <Icon iconType="copy-plus"/>
                    </template>
                  </AppButton>

                  <AppButton color="red" label="" :icon="true" @click.native.stop="deleteOrder(rowData.sales_order_id)">
                    <template v-slot:icon>
                      <Icon iconType="delete"/>
                    </template>
                  </AppButton>

                </div>
              </template>

              <!-- Expansion Template -->
              <template v-slot:expansion="{ data }">
                <div  class="p-5  ml-12 bg-gray-100 ">
                  <h5 class="mb-4">Despatch Note(s) for {{ data.sales_order_no }}</h5>
                  <DataTable :value="data.dispatch_notes">
                    <Column field="dispatch_note_no" header="Despatch Number" sortable />
                    <Column field="dispatch_date" header="Despatch Date" sortable>
                      <template #body="slotProps">
                        {{ formatTimestamp(slotProps.data.dispatch_date) }}
                      </template>
                    </Column>
                    <Column field="status" header="Status" sortable>
                      <template #body="slotProps">
                        <span :class="'order-badge order-' + slotProps.data.status.toLowerCase()">{{  checkStatusAndReturnUppercase(slotProps.data.status) }}</span>
                      </template>
                    </Column>
                    <Column field="courier" header="Courier" sortable />

                    <Column field="dispatched_by" header="Despatched By" sortable>
                      <template #body="slotProps">
                        {{ slotProps.data.dispatched_by.name }}
                      </template>
                    </Column>

                    <Column :headerStyle="{'width':'4rem'}">
                      <template #body="slotProps">
                        <div class="flex space-x-2">
                          <AppButton
                              color="blue"
                              label=""
                              :icon="true"
                              @click.native.stop="editDispatchNote(data.sales_order_id,slotProps.data)">
                            <template v-slot:icon>
                              <Icon iconType="edit"/>
                            </template>
                          </AppButton>

                          <AppButton
                              color="red"
                              label=""
                              :icon="true"
                              @click.native.stop="deleteDispatchNote(data.sales_order_id,slotProps.data)">
                            <template v-slot:icon>
                              <Icon iconType="delete"/>
                            </template>
                          </AppButton>
                        </div>
                      </template>
                    </Column>


                    <template #empty>
                      No records.
                    </template>
                  </DataTable>
                </div>
              </template>


            </DataTableComponent>
          </template>
        </TabContent>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
import TabHeaders from "@/components/TabHeaders.vue";
import TabContent from "@/components/TabContent.vue";
import DataTableComponent from "@/views/HR/Sales/Components/DataTableComponent.vue";
import {mapActions, mapState} from "vuex";
import Icon from "@/components/Icon.vue";
import notificationMixin from "@/mixins/notificationMixin";
import StaffAutocomplete from "@/components/AutoComplete/StaffAutoComplete.vue";
import AppButton from "@/components/Button.vue";

export default {
  mixins: [notificationMixin],
  components: {
    AppButton,
    PageHeader,
    Spinner,
    Panel,
    TabHeaders,
    TabContent,
    DataTableComponent,
    Icon,
    StaffAutocomplete
  },
  data() {
    return {
      tabs: [
        {id: "all_sales", label: "All Sales Orders",},
        {id: "open_orders", label: "Open Orders"},
        {id: "completed_orders", label: "Completed Orders",},
        {id: "dispatch_notes", label: "Despatch Notes",},
      ],

    };
  },

  computed: {
    ...mapState("sales", ["currentTab", "searchText", "fromDate", "toDate","lastSelectedRowId"]),

    ...mapState("sales", {
      currentTabState: state => state.tabs[state.currentTab],
    }),


    purchase_columns() {
      // Base columns
      const columns = [
        {
          field: "sales_order_no",
          header: "Sales Order No.",
          sortable: true,
          custom: false,
          width: '15%',
        },
        {
          field: "order_date",
          header: "Order Date",
          sortable: true,
          custom: true,
          slotName: "order-date-slot",
          width: '10%',
        },
        {
          field: "requested_delivery_date",
          header: "Requested Date",
          sortable: true,
          custom: true,
          slotName: "delivery-date-slot",
          width: '10%',
        },
        {
          field: "sold_to_contact.name",
          header: "Sold To",
          sortable: true,
          custom: false,
          width: '20%',
        },
        {
          field: "invoice_to_contact.name",
          header: "Invoice To",
          sortable: true,
          custom: false,
          width: '20%',
        },
        {
          field: "completed_date",
          header: "Completed Date",
          sortable: true,
          custom: true,
          slotName: "completed-date-slot",
          width: '10%',
        },
        {
          field: "status",
          header: "Status",
          sortable: true,
          custom: true,
          slotName: "status-slot",
          width: '10%',
        },
        {
          field: "action",
          header: "",
          sortable: false,
          custom: true,
          slotName: "action-slot",
          width: '10%',
        },
      ];

      // Conditionally add the expander column
      if (this.currentTabState.enableExpander) {
        columns.unshift({
          // Expander column definition
          expander: true,
          header: "",
          sortable: false,
          width: '3%', // Adjust width as necessary
        });
      }

      return columns;
    },


    searchTextInput: {
      get() {
        return this.searchText;
      },
      set(value) {
        this.$store.commit("sales/UPDATE_SEARCH_TEXT", value);
      },
    },

    selectedEngineerInput: {
      get() {
        return this.selectedEngineer;
      },
      set(value) {
        this.$store.commit("sales/UPDATE_SELECTED_ENGINEER", value);
      },
    },

    fromDateInput: {
      get() {
        const fromDate = this.fromDate;
        return fromDate ? new Date(fromDate) : null;
      },
      set(value) {
        this.$store.commit("sales/UPDATE_FROM_DATE", value);
      },
    },

    toDateInput: {
      get() {
        const toDate = this.toDate;
        return toDate ? new Date(toDate) : null;
      },
      set(value) {
        this.$store.commit("sales/UPDATE_TO_DATE", value);
      },
    },
  },
  mounted() {
    this.fetchTableData();
  },
  methods: {
    ...mapActions("sales", [
      "fetchTableData",
      "updateSearchCriteria",
      "updateTab",
      "resetCurrentPage",
    ]),

    validateDates() {
      const hasFromDate = !!this.fromDateInput;
      const hasToDate = !!this.toDateInput;

      if (hasFromDate && hasToDate) {
        if (this.toDateInput < this.fromDateInput) {
          this.notifyWarning("To Date should not be earlier than From Date.");
          return false;
        }
        return true;
      }

      if (hasFromDate !== hasToDate) {
        this.notifyWarning("Please select both the From Date and To Date.");
        return false;
      }

      return true;
    },

    onTabSelected(newTab) {

      this.updateTab(newTab);
    },

    triggerSearch() {
      if (this.validateDates()) {
        this.resetCurrentPage(0);
        this.updateTab(this.currentTab);
      }
    },


    async deleteDispatchNote(sales_order_id,dispatchNote) {

      const confirmation = await this.confirmAction({
        message: 'Do you want to delete this despatch note?',
        header: 'Confirm Action',
        acceptLabel: 'Yes',
        rejectLabel: 'No'
      });

      if (confirmation) {
        try {
          // Check if the dispatch note can be deleted

          if (dispatchNote && dispatchNote.status === 'not packed') {
            await this.SalesOrderService.deleteDispatchNote(sales_order_id, dispatchNote.dispatch_note_id);
            this.notifySuccess("Despatch note deleted successfully");
            this.updateTab(this.currentTab);
          } else {
            this.notifyError("Cannot delete this despatch note. Its current status is not allowed for deletion.");
          }
        } catch (error) {
          this.notifyError("Couldn't delete the despatch note");
          console.error(error);
        }
      }
    },

    editDispatchNote(sales_order_id,data) {

      this.$router.push({
        name: 'dispatch',
        params: {
          sales_order_id: sales_order_id,
          dispatch_note_id: data.dispatch_note_id
        }
      });
    },

    onRowClick(event) {

      if(this.currentTabState.enableExpander) {
        return;
      }

      this.$store.commit("sales/SET_LAST_SELECTED_ROW", event.data.sales_order_id);

      this.$router.push({
        name: 'sales-edit',
        params: {sales_order_id: event.data.sales_order_id}
      });
    },
    formatTimestamp(unixTimestamp) {

      if (!unixTimestamp) {
        return ''; // or any default value like 'N/A'
      }
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },
    checkStatusAndReturnUppercase(status) {

      if (status && status.toLowerCase() === 'items added') {
        return 'OPEN';
      }

      if (status && status.toLowerCase() === 'confirmed') {
        return 'OPEN';
      }

      return status ? status.toUpperCase() : 'N/A';
    },
    getDeliveryDateClass(rowData) {
      if (['part received', 'items added'].includes(rowData.status)) {
        return 'expiry-badge status-' + this.getDeliveryDateStatus(rowData.requested_delivery_date);
      }
      return 'expiry-badge status-normal'
    },
    getDeliveryDateStatus(requestedDeliveryDate) {
      const today = this.$moment();
      const deliveryDate = this.$moment.unix(requestedDeliveryDate);
      const daysDiff = deliveryDate.diff(today, "days");

      if (daysDiff < 0) return "row-red-light"; // Past due date
      if (daysDiff <= 14) return "row-orange-light"; // Due within 14 days
      return "normal"; // No specific color
    },

    rowClass(rowData) {
      const allowedStatuses = ["items added", "open",  'part dispatched','confirmed'];
      let classes = [];

      if (allowedStatuses.includes(rowData.status)) {
        const rowColor = this.getDeliveryDateStatus(rowData.requested_delivery_date);
        if (rowColor === "row-red-light") {
          classes.push("row-red-light");
        } else if (rowColor === "row-orange-light") {
          classes.push("row-orange-light");
        }
      }

      if (rowData.sales_order_id === this.lastSelectedRowId) {
        classes.push("highlight-row");
      }

      return classes.join(" ");
    },
    getTotalItems(rowData) {
      const counts = this.calculateTotalCounts(rowData);
      return counts.totalItems;
    },

    getDispatchedCounts(rowData) {
      const counts = this.calculateTotalCounts(rowData);
      return counts.totalDispatched;
    },

    calculateTotalCounts(data) {
      if (!data || !data.items || !data.items.length) {
        return { totalItems: 0, totalDispatched: 0 };
      }

      let totalItems = 0;
      let totalDispatched = 0;

      // Calculate total items from order items
      data.items.forEach(item => {
        totalItems += parseFloat(item.quantity || 0);
      });

      // Calculate total dispatched quantities from dispatch notes
      if (data.dispatch_notes && data.dispatch_notes.length) {
        data.dispatch_notes.forEach(note => {
          if (note.status === 'dispatched') {
            note.items.forEach(item => {
              totalDispatched += parseFloat(item.quantity || 0); // Sum dispatched item quantities
            });
          }
        });
      }

      return { totalItems, totalDispatched };
    },

    showCancelOrderButton(status) {
      const statuses = ['open','part dispatched','completed','confirmed'];
      return statuses.includes(status);
    },
    showCopyOrderButton(status) {
      const statuses = ['part dispatched','completed', 'cancelled', 'items added', 'open','confirmed'];
      return statuses.includes(status);
    },

    async cancelOrder(salesOrderId) {
      const confirmation = await this.confirmAction({
        message: 'Do you want to cancel this order?',
        header: 'Confirm Action',
        acceptLabel: 'Yes',
        rejectLabel: 'No'
      });

      if (confirmation) {
        try {
          const cancelledDate = this.$moment().unix();

          const updateData = {
            status: 'cancelled',
            cancelled_date: cancelledDate,
          };

          if (salesOrderId) {
            await this.SalesOrderService.updateSalesOrder(
                salesOrderId,
                updateData,
            );

            await this.fetchTableData()
            this.notifySuccess("Purchase order cancelled successfully");

          } else {
            this.notifyError("Purchase order ID is missing");
          }

        } catch (error) {
          this.notifyError("Couldn't cancel the purchase order");
          console.error(error);
        } finally {

        }
      }
    },

    async copyOrder(salesOrderId) {
      const confirmation = await this.confirmAction({
        message: 'Do you want to copy this order?',
        header: 'Confirm Action',
        acceptLabel: 'Yes',
        rejectLabel: 'No'
      });

      if (confirmation) {
        try {
          if (salesOrderId) {
            const newOrder = await this.SalesOrderService.copySalesOrder(salesOrderId);
            this.notifySuccess("Sales order copied successfully");

            await this.fetchTableData()
            this.$store.commit("sales/SET_LAST_SELECTED_ROW", newOrder.sales_order_id);

          } else {
            this.notifyError("Purchase order ID is missing");
          }

        } catch (error) {
          this.notifyError("Couldn't copy the purchase order");
          console.error(error);
        } finally {
        }
      }
    },
    async deleteOrder(salesOrderId) {
      const confirmation = await this.confirmAction({
        message: 'Do you want to delete this order?',
        header: 'Confirm Action',
        acceptLabel: 'Yes',
        rejectLabel: 'No'
      });

      if (confirmation) {
        try {
          if (salesOrderId) {
            await this.SalesOrderService.deleteSalesOrder(salesOrderId);
            this.notifySuccess("Sales order deleted successfully");
            await this.fetchTableData()
            // this.$router.go(-1);
          } else {
            this.notifyError("Sales order ID is missing");
          }

        } catch (error) {
          this.notifyError("Couldn't delete the sales order");
          console.error(error);
        } finally {
        }
      }
    },
  },
};
</script>

<style scoped>

::v-deep .row-red-light {
  color: red !important; /* Light shade of red */
}

::v-deep .row-orange-light {
  color: orange !important; /* Light shade of orange */
}

::v-deep .highlight-row {
  background-color: rgba(0,0,0,.15) !important;
}

</style>
